import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';
import quasarUserOptions from './quasar-user-options'
import router from './router'
import store from './store'
import VueApexCharts from 'vue3-apexcharts'
import cors from 'cors'
import DropZone from 'dropzone-vue'
import CKEditor from '@ckeditor/ckeditor5-vue'
import 'quasar/dist/quasar.css'

createApp(App)
  .use(DropZone)
  .use(cors)
  .use(store)
  .use(Quasar, {
    plugins: { Notify },
    ...quasarUserOptions
  })
  .use(router)
  .use(VueApexCharts)
  .use(CKEditor)
  .mount('#app')
